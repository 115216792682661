const server = {
 // url: "https://blackbox.saehanel.co.kr",
 // url: "http://221.143.48.220:2308",
 // url: "http://localhost:2401",
 // url: "http://221.143.48.220:2301",
//  url: "http://apis.data.go.kr",
  url: "https://ulji.saehanel.co.kr",
};

export default server;
